
import React from 'react';

import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';

import Home from './Home';
import Game from './Game';

import AdminQuestions from './Admin/Questions';
import AdminScores from './Admin/Scores';
import AdminUsers from './Admin/Users';
import AdminUserAnswers from './Admin/UserAnswers';
import AdminQuestionAnswers from './Admin/QuestionAnswers';
import AdminTimer from './Admin/Timer';
import AdminNavBar from './Admin/NavBar';

import ProfileDropdown from './ProfileDropdown';
import LoadingSpinner from './LoadingSpinner';
import { useAlert } from './AlertContext';

const routes = [
    // :gameId? picks up /game and /game/:gameId
    { path: "/game/:gameId?", Component: Game },
    { path: "/admin", requireAdmin: true, Component: AdminScores },
    { path: "/admin/users", requireAdmin: true, Component: AdminUsers },
    { path: "/admin/questions", requireAdmin: true, Component: AdminQuestions },
    { path: "/admin/timer", requireAdmin: true, Component: AdminTimer },
    { path: "/admin/users/:userId", requireAdmin: true, Component: AdminUserAnswers },
    { path: "/admin/questions/:questionId", requireAdmin: true, Component: AdminQuestionAnswers },
];

export default function Navigation({ user, checkingUser, setUser }) {
    return (<HashRouter>
        <Routes>
            { routes.map(r => <Route key={r.path} path={r.path} element={<RouteEle {...{ route: r, user, checkingUser }} />} />)}
            <Route path='/' element={<Home user={user} setUser={setUser} />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {user ? <ProfileDropdown user={user} setUser={setUser} /> : null}
        <NavAlertClear />
    </HashRouter>);
}

function RouteEle({ route, user, checkingUser }) {
    return <RequireAuth user={user} checkingUser={checkingUser} requireAdmin={route.requireAdmin}>
        { route.requireAdmin ? <AdminNavBar /> : null }
        <route.Component user={user} />
    </RequireAuth>;

}

function RequireAuth({ user, checkingUser, requireAdmin, children }) {
    if (user && (!requireAdmin || user.admin === true)) {
        return children;
    } else if (checkingUser) {
        return <LoadingSpinner />
    } else {
        return <Navigate to="/" />;
    }
}

function NavAlertClear() {
    const location = useLocation();
    const setAlert = useAlert();

    React.useEffect(() => {
        setAlert(null);
    }, [location, setAlert]);

    return null; // this is only for hooks... no obvious alternative
}
