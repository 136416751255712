
import React from 'react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import UsersImport from './UsersImport';
import fetchJson from '../../util/fetchJson';
import postJson from '../../util/postJson';
import { useAlert } from '../AlertContext';

export default function Users() {
    const [users, setUsers] = React.useState([]);
    const [action, setAction] = React.useState('');
    const setAlert = useAlert();

    React.useEffect(() => {
        fetchJson('/api/v1/user').then(setUsers).catch(error => {
            console.log(error);
            setAlert("Unable to load users!");
        });
    }, [action, setAlert]);

    return (<>
        <Container>
            <Row><Col><h4>User management</h4></Col></Row>
            <Row className="mb-1"><Col>
                <Button className="me-2" onClick={() => setAction("Add")}>Add User</Button>
                <Button onClick={() => setAction("Import")}>Import Users</Button>
            </Col></Row>
            <Row><Col>
                <table className="table">
                    <TableHeader />
                    <tbody>
                        {users.map(user => <UserRow key={user.id} user={user} setAlert={setAlert} />)}
                    </tbody>
                </table>
            </Col></Row>
        </Container>
        <Modal animation={false} show={!!action} onHide={() => setAction('')}>
            <Modal.Header closeButton>
                <Modal.Title>{action}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { action === "Add" ? <AddUserForm /> : <UsersImport users={users} closeCallback={() => setAction('')} /> }
            </Modal.Body>
        </Modal>
    </>);
}

function TableHeader() {
    return <thead><tr>
        <th scope="col">Username</th>
        <th scope="col">Name</th>
        <th scope="col">Admin</th>
        <th scope="col"></th>
    </tr></thead>;
}

function UserRow({ user, setAlert }) {
    return <tr>
        <td>{user.email}</td>
        <td>{user.firstName} {user.lastName}</td>
        <td>{user.admin ? "Yes" : ""}</td>
        <td><Button variant="link" onClick={() => resetPwd(user, setAlert)}>Reset password</Button></td>
    </tr>;
}

function resetPwd(user, setAlert) {
    const question = `Are you sure you want to reset the password for ${user.email}?` +
        ` A new password will be generated that must be sent to the user`;
    if (window.confirm(question)) {
        postJson('/api/v1/user/reset-password', { userId: user.id }).then(res => res.json()).then(res => {
            setAlert(passwordAlert("Password updated!", res.password));
        }).catch(error => {
            console.log(error);
            setAlert("Unable to update password!");
        });
    }
}

function passwordAlert(preText, password) {
    return {
        type: "success",
        message: `${preText} Provide the password to the user. `
            + `This password will not be accessible after closing this dialog.\n${password}`
    };
}

function AddUserForm() {
    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [admin, setAdmin] = React.useState(false);
    const [error, setError] = React.useState('');
    const setAlert = useAlert();

    const submit = (e) => {
        e.preventDefault();
        if (!email || !firstName || !lastName) {
            return;
        }
        if (admin && !window.confirm("Are you sure you want to add the user with admin privileges?")) {
            return;
        }
        const user = { email, firstName, lastName, admin };
        postJson('/api/v1/user', { user }).then(res => res.json()).then(res => {
            setEmail('');
            setFirstName('');
            setLastName('');
            setAdmin(false);
            setError('');
            setAlert(passwordAlert("User Added!", res.password));
        }).catch(error => {
            console.log(error)
            setError("Unable to add user. Check that the username is not already in use.");
        });
    };

    return <form onSubmit={submit}>
        <div className="form-group mb-3">
            <label htmlFor="username">Username</label>
            <input type="text" className="form-control" id="username" required={true}
                value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="form-group mb-3">
            <label htmlFor="firstName">First Name</label>
            <input type="text" className="form-control" id="firstName" required
                value={firstName} onChange={e => setFirstName(e.target.value)} />
        </div>
        <div className="form-group mb-3">
            <label htmlFor="lastName">First Name</label>
            <input type="text" className="form-control" id="lastName" required
                value={lastName} onChange={e => setLastName(e.target.value)} />
        </div>
        <div className="form-group form-check mb-3">
            <input type="checkbox" className="form-check-input" id="admin" checked={admin} onChange={e => setAdmin(e.target.checked)} />
            <label className="form-check-label" htmlFor="admin">Administrator</label>
        </div>
        <Button type="submit" variant="primary">Add User</Button>
        <div>
            <small className="form-text">Note: a password will be generated for the user that must then be sent to the user.</small>
        </div>
        <p className="text-danger">{error}</p>
    </form>;
}
