
import React from 'react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import fetchJson from '../../util/fetchJson';
import postJson from '../../util/postJson';
import { formatISO, parseISO } from 'date-fns';
import { useAlert } from '../AlertContext';
import Confirm from '../Confirm';

const INVALID_CLS = "border-danger";

export default function Timer() {
    const [timer, setTimer] = React.useState({});
    const [timerDates, setTimerDates] = React.useState({ start: '', stop: '' });
    const [toPost, setToPost] = React.useState(null);
    const setAlert = useAlert();

    React.useEffect(() => {
        fetchJson('/api/v1/timer').then(timer => setTimer(timer)).catch(error => {
            console.log(error);
            setAlert("Unable to load timer!");
        });
    }, [setAlert]);

    React.useEffect(() => {
        const update = { start: '', stop: '' };
        if (timer.start) {
            update.start = millisToInputStr(timer.start);
        }
        if (timer.stop) {
            update.stop = millisToInputStr(timer.stop);
        }
        setTimerDates(update);
    }, [timer]);

    // console.log(timerDates);
    const { start, stop } = timerDates;
    const submit = () => {
        if (!start || !stop || start > stop) {
            return;
        }
        setToPost({ start, stop });
    }

    const onConfirm = () => {
        const data = { start: inputStrToMillis(toPost.start), stop: inputStrToMillis(toPost.stop) };
        postJson('/api/v1/timer', data).then(res => res.json()).then(timer =>{
            setTimer(timer);
            setAlert({ type: "success", message: "Update successful" });
        }).catch(error => {
            console.log(error);
            setAlert("Unable to update timer!");
        }).finally(() => setToPost(null));
    };

    return (<Container>
        <Row><Col><h4>Competition timer management</h4></Col></Row>
        <Row><Col><p>
            Your timezone: {Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone || ""}
            {new Date().getTimezoneOffset() >= 0 ? " +" : " -"}{new Date().getTimezoneOffset()/60} hours
        </p></Col></Row>
        <Row>
            <Col xs='12' lg='6'>
                <Form.Group>
                    <Form.Label>Competition Start</Form.Label>
                    <Form.Control type='datetime-local' value={start}
                        className={!start || (stop && start > stop) ? INVALID_CLS : ''}
                        onChange={e => setTimerDates(v => ({...v, start: e.target.value}))} />
                    { !start ? <Form.Text className="text-danger">
                        You must provide a valid start date.
                    </Form.Text> : (stop && start > stop) ? <Form.Text className="text-danger">
                        The start date must be before the stop date.
                    </Form.Text> : null }
                </Form.Group>
            </Col>
            <Col xs='12' lg='6'>
                <Form.Group>
                    <Form.Label>Competition Stop</Form.Label>
                    <Form.Control type='datetime-local' value={stop}
                        className={!stop || (start && start > stop) ? INVALID_CLS : ''}
                        onChange={e => setTimerDates(v => ({...v, stop: e.target.value}))} />
                    { !stop ? <Form.Text className="text-danger">
                        You must provide a valid stop date.
                    </Form.Text> : (start && start > stop) ? <Form.Text className="text-danger">
                        The stop date must be after the start date.
                    </Form.Text> : null }
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col className="mt-2 d-flex justify-content-end" xs='12'>
                <Button variant='success' disabled={!start || !stop || start > stop} onClick={submit}>Submit</Button>
            </Col>
        </Row>
        <Confirm open={!!toPost} onConfirm={onConfirm} onCancel={() => setToPost(null)}
            message={`Are you sure you want to set the competition to ${toPost?.start} - ${toPost?.stop}?`} />
    </Container>);
}

function millisToInputStr(val) {
    return formatISO(new Date(val)).slice(0, 16); // strip off seconds and timezone
}

function inputStrToMillis(val) {
    return parseISO(val).valueOf();
}
