
import Button from 'react-bootstrap/Button';

import postJson from '../../util/postJson';

// common code for answer displays

export function TableHeader({ keyCol }) {
    return <thead><tr>
        <th scope="col">{keyCol}</th>
        <th scope="col">Answer(s)</th>
        <th scope="col">State</th>
        <th scope="col">Attempts</th>
        <th scope="col"></th>
    </tr></thead>;
}

export function DataRow({ setView, keyObj, keyText, answers }) {
    if (!answers) {
        answers = [];
    }
    const correct = answers.find(a => a.correct);
    const noSkips = answers.filter(a => !a.skipped);
    const skipped = noSkips.length < answers.length;
    let answerCol;
    if (correct) {
        answerCol = correct.answer;
    } else {
        answerCol = noSkips.map(a => <div key={a.id}>{a.answer}</div>);
    }

    return <tr>
        <td>{keyText}</td>
        <td>{answerCol}</td>
        <td>{correct ? "Correct" : (skipped ? "Skipped" : "Incomplete")}</td>
        <td>{noSkips.length}</td>
        <td>
            {noSkips.length < 1 ? null :
                <Button size="sm" variant="link" onClick={() => setView(keyObj, noSkips)}>Details</Button> }
        </td>
    </tr>;
}

export function AnswerDetails({ view, setToCorrect }) {
    const anyCorrect = !!view?.answers?.find(a => a.correct);

    return <>
        <p className="fw-bold">{view?.title}</p>
        <p className="fw-bold">Answer: {view?.question?.answer}</p>
        <p>User attempts: </p>
        <table className="table">
            <thead><tr>
                <th scope="col">Answer</th>
                <th scope="col">Correct</th>
                <th scope="col"></th>
            </tr></thead>
            <tbody>
                {view?.answers?.map(a => <AttemptRow key={a.id} attempt={a} setToCorrect={setToCorrect} anyCorrect={anyCorrect} />)}
            </tbody>
        </table>
    </>;
}

function AttemptRow({ attempt, setToCorrect, anyCorrect }) {
    const button = anyCorrect ? null :
        <Button size="sm" variant="link" onClick={() => setToCorrect(attempt)}>Mark correct</Button>
    return <tr>
        <td>{attempt.answer}</td>
        <td>{attempt.correct ? "yes" : "no"}</td>
        <td>{button}</td>
    </tr>
}

export async function markCorrect(attempt, setAlert) {
    await postJson(`/api/v1/answer/${attempt.id}`, { correct: true }).catch(error => {
        console.log(error);
        setAlert("Unable to mark correct!");
    });
}
