
import React from 'react';

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useAlert } from './AlertContext';

export default function Alert({ alert }) {
    const setAlert = useAlert();

    if (!alert) {
        return null;
    }
    const message = typeof alert === 'string' ? alert : alert.message;
    return <ToastContainer position='top-center' style={{ paddingTop: "5rem" }}>
        <Toast bg={alert.type || "danger"} onClose={() => setAlert(null)}>
            <Toast.Header>
                <strong className="me-auto" style={{ whiteSpace: "pre-wrap" }}>{message}</strong>
            </Toast.Header>
        </Toast>
    </ToastContainer>;
}
