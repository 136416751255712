
import fetchJson from "../../util/fetchJson";

export async function loadCategory(categoryId) {
    const params = new URLSearchParams({ categoryId });
    const [ questions, answers ] = await Promise.all([
        fetchJson('/api/v1/question?' + params),
        fetchJson('/api/v1/answer?userId=self&' + params),
    ]);
    const answersByQuestion = {};
    answers.forEach(a => {
        if (!answersByQuestion[a.questionId]) {
            answersByQuestion[a.questionId] = [];
        }
        answersByQuestion[a.questionId].push(a);
    });

    return { questions, answers: answersByQuestion };
}
