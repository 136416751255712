
import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import fetchJson from '../../util/fetchJson';
import TimerText from '../../util/TimerText';

import { competitionName } from '../../App';
import Category from './Category';
import LoadingSpinner from '../LoadingSpinner';

const SLUG = "/game";
const catClasses = "fw-bold py-3 mb-3 text-center";

export default function Game(/* { user } */) {
    const [currentCatId, setCurrentCatId] = React.useState(null);
    // categories data
    const [categories, setCategories] = React.useState([]);
    const [selected, setSelected] = React.useState(null);
    const navigate = useNavigate();
    const { gameId } = useParams();

    // load once on init
    React.useEffect(() => {
        fetchJson('/api/v1/category').then(categories => {
            if (categories.length > 0) {
                categories[categories.length - 1].last = true;
            }
            setCategories(categories);
        }).catch(error => {
            setCategories([]);
            console.log(error); // TODO
        })
    }, []);

    React.useEffect(() => {
        fetchJson('/api/v1/category/current').then(({ id }) => setCurrentCatId(id)).catch(error => {
            setCurrentCatId(null);
            console.log(error);
        });
    }, [selected]);

    React.useEffect(() => {
        if (currentCatId === null) {
            return;
        }
        const sub = parseInt(gameId);
        if (isNaN(sub) || sub > currentCatId) {
            setSelected(null);
            if (gameId) {
                navigate(SLUG);
            }
        } else {
            setSelected(categories.find(c => c.id === sub));
        }
    }, [currentCatId, navigate, gameId, categories]);

    const allComplete = categories.length > 0 && categories[categories.length - 1].complete;

    const selectCat = cat => navigate(cat ? SLUG + '/' + cat.id : SLUG);

    // selected category
    const catElement = <Category category={selected} setSelected={selectCat} allComplete={allComplete} />;

    return <>
        { (categories.length > 0 ) ? null : <LoadingSpinner /> }
        <Container fluid className="fw-bold">
            <Row className="text-center mb-3">
                <Col xs={{ span: 12, order: "last" }} md={{ span: 3, order: "first" }}>
                    <p className="mb-0"><TimerText /></p>
                </Col>
                <Col xs={{ span: 8, offset: 2}} md={{ span: 6, offset: 0}}>
                    <h3 className="text-center">Conquer the Hill</h3>
                    <h5 className="text-center mb-lg-0">{competitionName} Edition</h5>
                </Col>
            </Row>
        </Container>
        { selected ? catElement : <Container>
            { categories.map(cat =>
                <CategoryRow key={cat.id} category={cat} currentCatId={currentCatId} setSelected={selectCat} />)}
        </Container> }
    </>;
}

function CategoryRow({ category, currentCatId, setSelected }) {
    const currentCat = currentCatId !== null ? currentCatId : Number.MAX_VALUE;
    let bgClass = "bg-secondary";
    let subText = "locked";
    let clickable = false;
    if (category.id < currentCat) {
        bgClass = "bg-light clickable";
        subText = "view";
        clickable = true;
    } else if (category.id === currentCat) {
        bgClass = "bg-success clickable";
        subText = "enter";
        clickable = true;
    }

    return <Row><Col md={{ span: 8, offset: 2}}>
        <Card className={`${catClasses} ${bgClass}`} onClick={() => clickable && setSelected(category)}>
            <h4>{category.name}</h4>
            <h5>{subText}</h5>
        </Card>
    </Col></Row>;
}


