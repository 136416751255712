
import React from 'react';

import fetchJson from './util/fetchJson';
import Navigation from './components/Navigation';
import { AlertContext } from './components/AlertContext';
import Alert from './components/Alert';

export const competitionName = "Reign";
export const helpdeskText = "Need help? Join the Reign discord";
export const helpdeskLink = "https://discord.com/channels/1123636064217743532";

export default function App() {
    const [user, setUser] = React.useState();
    const [alert, setAlert] = React.useState();
    const [checkingUser, setCheckingUser] = React.useState(true);

    // on load, set page title
    React.useEffect(() => {
        document.title = `CyberForce - ${competitionName}`
    }, []);

    // on load, check if we're already logged in
    React.useEffect(() => {
        fetchJson('/api/v1/auth/validate').then(setUser).catch(error => {
            // user is not logged in
            console.log(error);
        }).finally(() => setCheckingUser(false));
    }, []);

    return <AlertContext.Provider value={setAlert}>
        <Navigation user={user} setUser={setUser} checkingUser={checkingUser} />
        <Alert alert={alert} />
    </AlertContext.Provider>;
}
