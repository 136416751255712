
import { NavLink } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default function NavBar() {
    const linkClass = ({ isActive }) => isActive ? "active nav-link" : "nav-link";
    return <Navbar expand="lg" bg="secondary" variant="dark">
        <Navbar.Toggle aria-controls="main-navbar" />
        <Navbar.Collapse id="main-navbar">
            <Nav>
                <NavLink to="/admin" end className={linkClass}>Scores</NavLink>
                <NavLink to="/admin/users" className={linkClass}>Users</NavLink>
                <NavLink to="/admin/questions" className={linkClass}>Questions</NavLink>
                <NavLink to="/admin/timer" className={linkClass}>Timer</NavLink>
                <NavLink to="/game" target="_blank" className={linkClass}>Game board</NavLink>
            </Nav>
        </Navbar.Collapse>
    </Navbar>;
}
