
import React from 'react';

import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { helpdeskLink, helpdeskText } from '../App';
import LoginModal from './LoginModal';

import background from '../images/background.png';

const backgroundRgb = "rgb(67,55,101)";

export default function Home({ user, setUser }) {
    const [ login, setLogin ] = React.useState(false);
    const navigate = useNavigate();

    // dynamic recolor of html background for just this page
    React.useEffect(() => {
        const styleElement = document.createElement("style");
        styleElement.innerHTML = `html { background-color: ${backgroundRgb}; }`;
        document.head.appendChild(styleElement);
        return () => document.head.removeChild(styleElement);
    }, []);

    const onClick = () => {
        if (user) {
            doRedirect(user, navigate);
        } else {
            setLogin(true);
        }
    }

    const btnText = user ? "Launch" : "Login";

    return <div style={{ backgroundColor: backgroundRgb }}>
        <LoginModal setUser={setUser} login={login} setLogin={setLogin} navigate={navigate} doRedirect={doRedirect} />
        <h1>Conquer the Hill</h1>
        <img src={background} className="w-100" alt="splash background" />
        <a className="btn btn-lg p-0 position-absolute" style={{ fontSize: "2rem", bottom: 10, right: 10 }}
            target="_blank" rel="noreferrer" href={helpdeskLink} title={helpdeskText}>
            <FontAwesomeIcon icon={faQuestionCircle} />
        </a>
        <div className='text-center w-100'>
            <Button onClick={onClick} size="lg" variant="dark" className="fw-bold">{btnText}</Button>
        </div>
    </div>;
}

function doRedirect(user, navigate) {
    navigate((user.admin === true) ? '/admin' : '/game');
}
