
/**
 * Get JSON from the given path
 * @param {string} path 
 * @returns 
 */
export default function fetchJson(path) {
    return fetch(path).then(res => {
        if (res.status !== 200) {
            throw new Error("Error fetching JSON: " + res.status + " - " + res.statusText);
        }
        return res.json();
    })
}
