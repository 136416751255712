
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

/**
 * @param {object} props 
 * @param {boolean} props.open
 * @param {string?} props.title
 * @param {string} props.message
 * @param {import("react").MouseEventHandler} props.onConfirm
 * @param {import("react").MouseEventHandler} props.onCancel
 * @returns 
 */
export default function Confirm({ open, title, message, onConfirm, onCancel }) {

    return (<Modal show={open} centered backdrop="static" backdropClassName="confirm-backdrop" className="confirm-modal">
        {title ? <Modal.Header>{title}</Modal.Header> : null}
        <Modal.Body>
            {message}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={onCancel}>Cancel</Button>
            <Button variant="success" onClick={onConfirm}>Confirm</Button>
        </Modal.Footer>
    </Modal>);
}