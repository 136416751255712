
import { createContext, useContext } from "react";

export const AlertContext = createContext(null);

/**
 * @returns {React.Dispatch<React.SetStateAction<string|{ message: string, type: string }>>}
 */
export function useAlert() {
    return useContext(AlertContext);
}
