
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import postJson from '../../util/postJson';

import AttemptModal from './AttemptModal';
import HintModal from './HintModal';
import LoadingSpinner from '../LoadingSpinner';
import { loadCategory } from './data';
import { useAlert } from '../AlertContext';

const WARN_LAST = "Are you sure you want to submit your final answer? Your final time will be saved and you will not be able to answer any extra challenges afterwards.";

export default function Category({ category, setSelected, allComplete }) {
    const [questions, setQuestions] = React.useState([]);
    const [answers, setAnswers] = React.useState({}); // quesiton id -> answer list
    const [refresh, setRefresh] = React.useState(false);
    const [viewAttempts, setViewAttempts] = React.useState({});
    const [showHintsFor, setShowHintsFor] = React.useState(null);
    const setAlert = useAlert();

    React.useEffect(() => {
        let discard = false;
        setQuestions([]);
        setAnswers({});
        if (!category) { return; }
        loadCategory(category.id).then(({ questions, answers }) => {
            if (discard) return;
            setQuestions(questions);
            setAnswers(answers);
        }).catch(error => {
            if (discard) return;
            console.log(error);
            setAlert("Unable to load category!");
            setSelected(null);
        });
        return () => (discard = true);
    }, [category, setSelected, refresh, setAlert]);

    // on an answer change, check for last questions
    React.useEffect(() => {
        // find the last non-bonus, unanswered question and mark as such
        if (questions && answers) {
            const lastQuestions = questions.filter(
                q => !q.bonus && !(answers[q.id] || []).find(a => a.correct || a.skipped)
            );
            if (lastQuestions.length === 1 && !lastQuestions[0].last) {
                const idx = questions.indexOf(lastQuestions[0]);
                const newQuestions = [...questions];
                newQuestions[idx] = { ...lastQuestions[0], last: true };
                setQuestions(newQuestions);
            }
        }
    }, [questions, answers]);

    const doRefresh = () => setRefresh(v => !v);
    const newAnswer = answer => setAnswers(oldAnswers => {
        const newAnswers = {...oldAnswers};
        if (!newAnswers[answer.questionId]) {
            newAnswers[answer.questionId] = [ answer ];
        } else {
            newAnswers[answer.questionId] = [...newAnswers[answer.questionId], answer];
        }
        return newAnswers;
    });
    const lastCat = !!category?.last;
    const rowArgs = { doRefresh, newAnswer, setViewAttempts, setShowHintsFor, allComplete, lastCat };

    return <>
        { questions.length > 0 ? null : <LoadingSpinner /> }
        <Container className="border rounded">
            <Row className="mb-3">
                <Col xs={{ span: 3}} className="d-flex align-items-center">
                    <Button variant="link" onClick={() => setSelected(null)}>&lt;- Back</Button>
                </Col>
                <Col xs={{ span: 6}} className="text-center">
                    <h3>{category?.name}</h3>
                    {category?.description ? <p className="fw-bold bg-warning">{category.description}</p> : null}
                </Col>
            </Row>
            { questions.map(question =>
                <QuestionRow key={question.id} {...rowArgs} question={question} answers={answers[question.id] || []} />)}
        </Container>
        <AttemptModal {...viewAttempts} close={() => setViewAttempts({})} />
        <HintModal question={showHintsFor} close={() => setShowHintsFor(null)} />
    </>;
}

function QuestionRow({ question, answers, doRefresh, newAnswer, setViewAttempts, setShowHintsFor, allComplete, lastCat }) {
    const [guess, setGuess] = React.useState('');
    const [final, setFinal] = React.useState(null);
    const setAlert = useAlert();

    React.useEffect(() => {
        let finalAnswer = answers.filter(a => a.correct)[0];
        if (!finalAnswer) {
            finalAnswer = answers.filter(a => a.skipped)[0];
        }
        setFinal(finalAnswer);
    }, [answers]);

    React.useEffect(() => {
        setGuess(final?.answer || '')
    }, [final, answers]);

    const userAnswers = answers.filter(a => !a.skipped);
    const hasHints = (question.question_hints || []).length > 0

    return <Row className="no-gutters mb-3">
        <Col lg={{span: 2}} className="d-flex align-items-center">
            <div className="text-lg-end px-2 w-100 fw-bold">{question.question}</div>
        </Col>
        <Col lg={{span: 6}} xs={{span: 8}} className="mb-3 mb-lg-0">
            <input type="text" className="form-control" disabled={final || allComplete}
                value={guess} onChange={e => setGuess(e.target.value)} />
        </Col>
        <Col lg={{span: 1}} xs={{span: 4}} className="d-flex align-items-center mb-3 mb-lg-0">
            {final ? <div className="text-center px-2 w-100">{final.correct ? "Correct" : "Skipped"}</div> :
                <Button variant="success" className="w-100" title="Submit answer" disabled={!guess || allComplete}
                    onClick={() => submit(question, guess, doRefresh, newAnswer, lastCat, setAlert)}>Submit</Button>
            }
        </Col>
        <Col lg={{span: 2}} xs={{span: 6}} className="px-3">
            <Button variant="link" className="w-100" title="View attempts" disabled={userAnswers.length < 1}
                onClick={() => setViewAttempts({question, answers: userAnswers})}>
                    {userAnswers.length} {userAnswers.length === 1 ? "attempt" : "attempts"}
            </Button>
        </Col>
        <Col lg={{span: 1}} xs={{span: 6}} className="px-1">
            <Button variant="primary" className="w-100" title={!hasHints ? "No hints available" : "Open the hints dialog"}
                disabled={!!final || !hasHints || allComplete} onClick={() => setShowHintsFor(question)}>Hints</Button>
        </Col>
    </Row>;
}

function submit(question, guess, doRefresh, newAnswer, lastCat, setAlert) {
    const data = { questionId: question.id, answer: guess };
    let url = '/api/v1/answer';
    if (lastCat && !!question.last) {
        if (!window.confirm(WARN_LAST)) {
            return;
        }
    }
    postJson(url, data).then(res => res.json()).then(answer => {
        newAnswer(answer);
        if (!answer.correct && !answer.skipped) {
            setAlert(answer.answer + " was not correct");
        } else if (lastCat && !!question.last) {
            window.location.reload(); // competition finished
        }
    }).catch(error => {
        console.log(error);
        setAlert("Error submitting answer!");
        doRefresh();
    });
}
