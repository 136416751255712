
import React from 'react';

import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Modal from 'react-bootstrap/Modal';

import fetchJson from '../../util/fetchJson';
import { useAlert } from '../AlertContext';

// import QuestionEditor from './QuestionEditor';

/** @type {import('react').CSSProperties} */
const LONG_TEXT_STYLE = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100px",
};

export default function Questions() {
    const [questions, setQuestions] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    const [filter, setFilter] = React.useState({});
    const [categories, setCategories] = React.useState([]);
    // const [toEdit, setToEdit] = React.useState(null);
    const setAlert = useAlert();

    React.useEffect(() => {
        fetchJson('/api/v1/question').then(setQuestions).catch(error => {
            console.log(error);
            setAlert("Unable to load questions!");
        })
    }, [setAlert]);

    React.useEffect(() => {
        fetchJson('/api/v1/category').then(setCategories).catch(error => {
            console.log(error);
            setAlert("Unable to load categories!");
        })
    }, [setAlert]);

    React.useEffect(() => {
        setFiltered(applyfilter(questions, filter))
    }, [questions, filter]);

    // method to update questions with edited question
    // const notifyUpdated = (updatedQuestion) => {
    //     setQuestions(questions => {
    //         let update = [...questions];
    //         let idx = update.findIndex(q => q.id === updatedQuestion.id);
    //         if (idx >= 0) {
    //             update[idx] = updatedQuestion;
    //         } else {
    //             update.push(updatedQuestion);
    //         }
    //         return update;
    //     });
    //     setToEdit(null);
    // };

    return <>
        <Container>
            <Row><Col><h4>Question management</h4></Col></Row>
            <Row className="mb-1">
                <Col xs="1">Filters</Col>
                <Col xs="2"><CategoryFilter categories={categories} filter={filter} setFilter={setFilter} /></Col>
                <Col xs="2"><TextFilter attribute={"question"} filter={filter} setFilter={setFilter} /></Col>
                <Col xs="2"><TextFilter attribute={"answer"} filter={filter} setFilter={setFilter} /></Col>
                <Col xs="2"><Button variant="link" onClick={() => setFilter({})}>Clear filter</Button></Col>
            </Row>
            <Row><Col>
                <table className="table">
                    <TableHeader />
                    <tbody>
                        {filtered.map(q => <TableRow key={q.id} question={q} />)}
                    </tbody>
                </table>
            </Col></Row>
        </Container>
        {/* <Modal size="lg" animation={false} show={!!toEdit} onHide={() => setToEdit(null)}>
            <Modal.Header closeButton>
                <Modal.Title>Edit question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <QuestionEditor toEdit={toEdit} notifyUpdated={notifyUpdated}/>
            </Modal.Body>
        </Modal> */}
    </>;
}

function TableHeader() {
    return <thead><tr>
        <th scope="col">Id</th>
        <th scope="col">Category</th>
        <th scope="col">Type</th>
        <th scope="col">Question</th>
        <th scope="col">Answer</th>
        <th scope="col">Time Bonus</th>
        <th scope="col"></th>
    </tr></thead>;
}

function TableRow({ question }) {
    return <tr>
        <td>{question.id}</td>
        <td>{question.categoryId}</td>
        <td>{question.bonus ? "Bonus" : "Standard" }</td>
        <td style={LONG_TEXT_STYLE} title={question.question}>{question.question}</td>
        <td style={LONG_TEXT_STYLE} title={question.answer}>{question.answer}</td>
        <td>{question.minutes ? `${question.minutes} minutes` : "" }</td>
        <td>
            {/* <Button size="sm" variant="link" className="me-2" onClick={() => setToEdit(question)}>Edit</Button> */}
            <Link className="btn btn-link btn-sm" to={`./${question.id}`}>Answers</Link>
        </td>
    </tr>;
}

function CategoryFilter({ categories, filter, setFilter }) {
    const onChange = e => setFilter(filter => {
        const categoryId = parseInt(e.target.value);
        if (categoryId >= 0) {
            return { ...filter, categoryId };
        }
        let copy = { ...filter };
        delete copy.categoryId;
        return copy;
    });

    return <select className="form-control form-control-sm" value={filter.categoryId ?? -1} onChange={onChange}>
        <option value={-1}>All Categories</option>
        {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
    </select>;
}

function TextFilter({ attribute, filter, setFilter }) {
    const onChange = e => setFilter(filter => {
        const { value } = e.target;
        if (value) {
            return { ...filter, [attribute]: value };
        }
        let copy = { ...filter };
        delete copy[attribute];
        return copy;
    });

    return <input className="form-control form-control-sm" type="text" placeholder={`${attribute} text`}
        value={filter[attribute] || ""} onChange={onChange} />;
}

function applyfilter(questions, filter) {
    if (!filter) {
        return questions;
    }
    const filters = Object.keys(filter);
    if (filters.indexOf("categoryId") >= 0) {
        questions = questions.filter(q => q.categoryId === filter.categoryId);
    }
    if (filters.indexOf("question") >= 0) {
        let search = filter.question.toLowerCase();
        questions = questions.filter(q => q.question.toLowerCase().indexOf(search) >= 0);
    }
    if (filters.indexOf("answer") >= 0) {
        let search = filter.answer.toLowerCase();
        questions = questions.filter(q => q.answer.toLowerCase().indexOf(search) >= 0);
    }
    
    return questions;
}
