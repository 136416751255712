
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import Dropdown from 'react-bootstrap/Dropdown';

import { helpdeskLink, helpdeskText } from '../App';

export default function ProfileDropdown({ user, setUser }) {

    const navigate = useNavigate();
    const isAdmin = user.admin === true;
    // display in the top right hand corner
    return <div className="position-absolute" style={{ top: 0, right: 0, zIndex: 5 }}>
        <Dropdown>
            <Dropdown.Toggle variant="light" className="py-0" style={{ fontSize: "x-large" }}>
                <FontAwesomeIcon icon={faUserCircle} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <span className="dropdown-item-text">Hello {user.firstName}</span>
                { !isAdmin ? null : <Dropdown.Item onClick={() => navigate('/admin')}>Admin site</Dropdown.Item>}
                <Dropdown.Item target="_blank" rel="noreferrer" href={helpdeskLink} title={helpdeskText}>
                    Need help?
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={e => doLogout(setUser)}>Log out</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
}

function doLogout(setUser) {
    const opts = { method: 'POST', cache: 'no-cache' };
    fetch('/api/v1/auth/logout', opts).then(() => setUser(null)).catch(error => {
        console.log("error logging out", error);
    });
}