
import React from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

export default function LoginModal({ setUser, login, setLogin, navigate, doRedirect }) {
    const [username, setUsername] = React.useState();
    const [password, setPassword] = React.useState();
    const [error, setError] = React.useState();

    React.useEffect(() => {
        setUsername('');
        setPassword('');
        setError('');
    }, [login]);

    const handleSubmit = e => {
        e.preventDefault();
        const opts = {
            method: 'POST',
            cache: 'no-cache',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password })
        };
        setPassword('');
        fetch("/api/v1/auth/login", opts).then(res => {
            if (res.status !== 200) {
                throw new Error("Unable to log in");
            }
            return res.json();
        }).then(user => {
            setUser(user);
            setLogin(false);
            doRedirect(user, navigate);
        }).catch(error => {
            setError("Unable to log in");
            console.log(error);
        });
    };

    return <Modal centered animation={false} show={!!login} onHide={() => setLogin(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-2">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" placeholder="username" autoComplete="off"
                        value={username} onChange={e => setUsername(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" autoComplete="off" value={password} onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <Button type="submit">Login</Button>
                <p className="text-danger">{error}</p>
            </Form>
        </Modal.Body>
    </Modal>
}
