import Spinner from "react-bootstrap/Spinner";

export default function LoadingSpinner() {
    const style = { background: "rgba(0,0,0,0.5)", zIndex: 9999, top: 0, left: 0 };
    return (
        <div className="position-absolute w-100 h-100 d-flex justify-content-center" style={style}>
        <Spinner animation="border" variant="light" role="status" size="lg" className="m-4">
                <span className="visually-hidden">Loading...</span>
        </Spinner>
        </div>
    );
}
