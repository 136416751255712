
import React from 'react';

import Button from 'react-bootstrap/Button';

import { readString, jsonToCSV } from 'react-papaparse';

import postJson from '../../util/postJson';

export default function UsersImport({ users, closeCallback }) {
    const [file, setFile] = React.useState(null);
    const [fileContents, setFileContents] = React.useState(null);
    const [parsed, setParsed] = React.useState(null);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setFileContents(null);
        setError(null);
        if (file) {
            // process the file async
            parseFile(file).then(setFileContents).catch(setError);
        }
    }, [file]);

    React.useEffect(() => {
        try {
            setParsed(parseContents(fileContents, users));
        } catch (error) {
            setParsed(null);
            setError(error);
        }
    }, [fileContents, users]);

    const submit = () => {
        setError(null);
        const users = parsed?.newUsers || [];
        Promise.allSettled(users.map(user =>
            postJson('/api/v1/user', { user }).then(res => res.json()).catch(() => {
                throw new Error("Unable to add user " + user.email);
            })
        )).then(results => {
            const errors = [], newUsers = [];
            results.forEach(r => {
                if (r.status === "rejected") {
                    errors.push(r.reason.message);
                } else {
                    newUsers.push(r.value);
                }
            });
            if (newUsers.length > 0) {
                downloadResult(newUsers);
            }
            if (errors.length > 0) {
                setError({ message: errors.join("; ") });
            } else {

                closeCallback();
            }
        });
        setParsed(null);
    };

    return (<>
        <p>
            Choose a CSV file to import. The CSV must have columns: "username", "firstName", "lastName".
            When submitted, a csv file will download with the password details for created users.
        </p>
        <div className="d-flex justify-content-between mb-3">
            <input type="file" accept="text/csv,.csv" onChange={e => setFile(e.target.files[0])} />
        </div>
        {file ? <Summary {...{parsed, error, submit}} /> : null }
    </>);
}

function Summary({ parsed, error, submit }) {
    const [show, setShow] = React.useState(false);

    let userList = null;
    if (show && parsed) {
        userList = <>
            <p className="mb-0 mt-2">New Users:</p>
            <ul>{parsed?.newUsers?.map((user, index) => <li key={index}>{user.email} - {user.firstName} {user.lastName}</li>)}</ul>
            <p className="mb-0 mt-2">Duplicates (will be ignored):</p>
            <ul>{parsed?.duplicates?.map((user, index) => <li key={index}>{user.email} - {user.firstName} {user.lastName}</li>)}</ul>
        </>;
    }

    return <>
        <h5>File summary:</h5>
        { error || parsed ? null : <p>Processing...</p> }
        { error ? <p>Error: {error.message}</p> : null }
        { parsed ? <p>New users: {parsed.newUsers.length} <br /> Duplicates: {parsed.duplicates.length}</p> : null }
        { parsed ?
            <Button className="me-2" type="button" variant="primary" onClick={() => setShow(val => !val)} >Show details</Button> : null }
        { parsed?.newUsers?.length > 0 ? <Button type="button" variant="success" onClick={submit}>Add users</Button> : null }
        { userList }
    </>;
}

function parseFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('error', e => {
            console.log(e);
            reject("Error reading file!");
        });
        reader.addEventListener('load', e => resolve(e.target.result));
        reader.readAsText(file);
    }).then(parseLines);
}

function parseLines(contents) {
    if (!contents || !(contents = contents.trim())) {
        throw new Error("File is empty!");
    }
    // just give us array of arrays (header false)
    return readString(contents, { header: true });
}

function parseContents(fileContents, users) {
    if (!fileContents || !fileContents.data) {
        return null; // nothing to parse
    }
    // make sure we got the columns we need
    const fields = fileContents.meta?.fields;
    if (!fields || fields.length < 3) {
        throw new Error('Missing headers in file! Must have: "username", "firstName", "lastName"');
    }
    const emailFieldName = fieldName(fields, "username");
    const lastFieldName = fieldName(fields, "lastName");
    const firstFieldName = fieldName(fields, "firstName");
    // convert the data objects to what we need and ignore empties
    const toAdd = fileContents.data.map(user => ({
        email: (user[emailFieldName] || "").trim(),
        firstName: (user[firstFieldName] || "").trim(),
        lastName: (user[lastFieldName] || "").trim()
    })).filter(user => user.email && user.firstName && user.lastName);
    // now remove any dupes based on email
    // also filter out dupes only in the new set
    const duplicates = [], newUsers = [];
    const emails = users.map(u => u.email.trim().toLowerCase());
    toAdd.forEach((user, index) => {
        if (emails.indexOf(user.email.toLowerCase()) >= 0
            || index !== toAdd.findIndex(u2 => user.email.toLowerCase() === u2.email.toLowerCase())) {
            duplicates.push(user);
        } else {
            newUsers.push(user);
        }
    });
    return { newUsers, duplicates };
}

function fieldName(fields, expected) {
    const match = expected.toLowerCase();
    for (let field of fields) {
        if (match === field.trim().toLowerCase()) {
            return field;
        }
    }
    throw new Error("Missing field: " + expected);
}

function downloadResult(users) {
    
    const csv = jsonToCSV(users, { newline: "\n" });
    const output = new Blob([csv], { type: "data:text/csv;charset=utf-8"});
    console.log("generated csv blob size", output.size);

    const a = document.createElement("a");
    const url = URL.createObjectURL(output);
    // if `a` element has `download` property
    if ("download" in a) {
        a.href = url;
        a.download = "anomaly-users.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else { // use `window.open()` if `download` not defined at `a` element
        window.open(url);
    }
}
