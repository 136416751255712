
/**
 * POST a json object to the given path
 * @param {string} path 
 * @param {object} data 
 * @param {boolean} usePatch
 * @returns 
 */
export default function postJson(path, data, usePatch = false) {
    const body = JSON.stringify(data);
    return fetch(path, {
        method: usePatch === true ? 'PATCH' : 'POST',
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Length' ?
        },
        body
    }).then(res => {
        if (res.status !== 200) {
            throw new Error("Error posting JSON: " + res.status + " - " + res.statusText);
        }
        return res;
    })
}
