
import Modal from "react-bootstrap/Modal";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function AttemptModal({question, answers, close}) {

    return <Modal size="lg" animation={false} show={!!question} onHide={close}>
        <Modal.Header closeButton>
            <Modal.Title>{question?.question} attempts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="mb-3 fw-bold">
                <Col xs="8">Answer</Col>
                <Col xs="4">Result</Col>
            </Row>
            {answers?.map(a => <Row key={a.id} className="mb-3">
                <Col xs="8">{a.answer}</Col>
                <Col xs="4">{a.correct ? "Correct" : (a.skipped ? "Skipped" : "Wrong")}</Col>
            </Row>)}
        </Modal.Body>
    </Modal>;
}
