
import React from 'react';

import fetchJson from './fetchJson';

import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';

export default function TimerText() {
    const [timer, setTimer] = React.useState(null);
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        fetchJson("/api/v1/timer").then(setTimer).catch(console.log).then(() => setLoading(false));
    }, []);
    
    React.useEffect(() => {
        setMessage(getMessageString(timer, loading));
        const interval = setInterval(() => setMessage(getMessageString(timer, loading)), 500);
        return () => clearInterval(interval);
    }, [timer, loading]);

    return message;
}

function getMessageString(timer, loading) {
    if (loading) {
        return "";
    } else if (!timer) {
        return "Competition is over";
    }
    const { start, stop } = timer;
    const now = Date.now();
    if (now > stop) {
        return "Competition is over";
    } else if (now > start) {
        return "Competition ends in " + duration(stop, now);
    } else {
        return "Competition starts in " + duration(start, now);
    }
}

/**
 * Return the duration as a string between the two dates
 * @param {Number} timeLeft - epoch millis greater than timeRight
 * @param {Number} timeRight - epoch millis less than timeLeft
 */
function duration(timeLeft, timeRight) {
    const diffDay = differenceInDays(timeLeft, timeRight);
    const textDay = diffDay === 1 ? "day" : "days";
    const diffHrs = differenceInHours(timeLeft, timeRight) % 24;
    const textHrs = diffHrs === 1 ? "hour" : "hours";
    const diffMin = differenceInMinutes(timeLeft, timeRight) % 60;
    const textMin = diffMin === 1 ? "minute" : "minutes";
    const diffSec = differenceInSeconds(timeLeft, timeRight) % 60;
    const textSec = diffSec === 1 ? "second" : "seconds";

    if (diffDay > 0) {
        return `${diffDay} ${textDay} ${diffHrs} ${textHrs}`;
    }
    if (diffHrs > 0) {
        return `${diffHrs} ${textHrs} ${diffMin} ${textMin}`;
    }
    if (diffMin > 0) {
        return `${diffMin} ${textMin} ${diffSec} ${textSec}`;
    }
    return `${diffSec} ${textSec}`;
}
