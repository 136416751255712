
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import fetchJson from '../../util/fetchJson';

import { TableHeader, DataRow, AnswerDetails, markCorrect } from './Answers';
import { Navigate, useParams } from 'react-router-dom';
import Confirm from '../Confirm';
import { useAlert } from '../AlertContext';

const INTEGER_REGEX = /^\d+$/;

export default function QuestionAnswers() {
    const { questionId } = useParams();
    const [question, setQuestion] = React.useState(null);
    const [answers, setAnswers] = React.useState({});
    const [users, setUsers] = React.useState([]);
    const [view, setView] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false);

    const [sort, setSort] = React.useState("");
    const [filter, setFilter] = React.useState("");
    const [userFilter, setUserFilter] = React.useState("");
    const [answerFilter, setAnswerFilter] = React.useState("");
    const [toCorrect, setToCorrect] = React.useState(null);
    const setAlert = useAlert();

    React.useEffect(() => {
        loadData(questionId, setQuestion, setAnswers, setUsers)
    }, [questionId, refresh]);

    React.useEffect(() => {
        setView(old => old ? { ...old, answers: answers[old.user.id]} : null);
    }, [answers]);

    const update = () => setRefresh(old => !old);

    React.useEffect(() => {
        applySort(sort, answers, setUsers)
    }, [sort, answers]);

    if (!questionId || !INTEGER_REGEX.test(questionId)) {
        return <Navigate to="/" />;
    }

    // this is not the ideal way to filter but...
    let filtered = !filter ? users : users.filter(user => {
        const hasCorrect = !!(answers[user.id]?.find(a => a.correct));
        if (filter === "correct") {
            return hasCorrect;
        } else {
            return !hasCorrect;
        }
    });
    if (userFilter) {
        filtered = filtered.filter(user => `${user.firstName} ${user.lastName}`.indexOf(userFilter) >= 0);
    }
    if (answerFilter) {
        filtered = filtered.filter(user => !!(answers[user.id]?.find(a => a.answer.indexOf(answerFilter) >= 0)));
    }
    const rowSetView = (user, answers) => setView({ tite: formatUser(user), question, answers, user });
    const onCorrect = () => markCorrect(toCorrect, setAlert).finally(() => {
        setToCorrect(null);
        update();
    });

    return (<>
        <Container>
            <Row><Col><h4>Scores for {question?.question}</h4></Col></Row>
            <Row className="mb-2">
                <Col xs="3">Sort by <Sorter sort={sort} setSort={setSort} /></Col>
                <Col xs="3"><Filter filter={filter} setFilter={setFilter} /></Col>
                <Col xs="3"><input type="text" className="form-control form-control-sm" placeholder="User name filter"
                    value={userFilter} onChange={e => setUserFilter(e.target.value)} /></Col>
                <Col xs="3"><input type="text" className="form-control form-control-sm" placeholder="Answer filter"
                    value={answerFilter} onChange={e => setAnswerFilter(e.target.value)} /></Col>
            </Row>
            <Row><Col>
                <table className="table">
                    <TableHeader keyCol="User" />
                    <tbody>
                        {filtered.map(user =>
                            <DataRow key={user.id} keyObj={user} setView={rowSetView}
                            keyText={formatUser(user)} answers={answers[user.id]} />)}
                    </tbody>
                </table>
            </Col></Row>
        </Container>
        <Modal size="lg" animation={false} show={!!view} onHide={() => setView(null)}>
            <Modal.Header closeButton>
                <Modal.Title>View</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AnswerDetails {...{ view, question, setToCorrect }}/>
            </Modal.Body>
        </Modal>
        <Confirm open={!!toCorrect} message="Are you sure you want to mark the answer as correct?"
            onConfirm={onCorrect} onCancel={() => setToCorrect(null)}/>
    </>);
}

function loadData(questionId, setQuestion, setAnswers, setUsers) {
    questionId = parseInt(questionId);
    setQuestion(null);
    setAnswers({});
    setUsers([]);

    fetchJson('/api/v1/question').then(questions =>
        setQuestion(questions.find(q => q.id === questionId))).catch(console.log);

    fetchJson('/api/v1/answer?questionId=' + questionId).then(list => {
        const answers = {};
        list.forEach(answer => {
            if (!answers[answer.userId]) {
                answers[answer.userId] = [];
            }
            answers[answer.userId].push(answer);
        });
        setAnswers(answers);
    }).catch(console.log);

    fetchJson('/api/v1/user').then(setUsers).catch(console.log);
}

function formatUser(user) {
    return user ? [user.id, '-', user.firstName, user.lastName].join(" ") : "";
}

function Sorter({ sort, setSort }) {
    return <select className="form-control form-control-sm d-inline-block w-auto ms-2"
                value={sort} onChange={e => setSort(e.target.value)}>
        <option value="">None</option>
        <option value="firstName">First Name</option>
        <option value="lastName">Last Name</option>
        <option value="attempts">Attempts</option>
    </select>;
}

function applySort(sort, answers, setUsers) {
    setUsers(old => {
        const updated = [...old];
        if (!sort) {
            updated.sort((u1, u2) => u1.id - u2.id);
        } else if (sort === "attempts") {
            updated.sort((u1, u2) => (answers[u1.id] || []).length - (answers[u2.id] || []).length);
        } else {
            updated.sort((u1, u2) => u1[sort].localeCompare(u2[sort]));
        }
        return updated;
    });
}

function Filter({ filter, setFilter }) {
    return <select className="form-control form-control-sm"
                value={filter} onChange={e => setFilter(e.target.value)}>
        <option value="">Show All</option>
        <option value="correct">Correct</option>
        <option value="incorrect">Incorrect</option>
    </select>;
}
