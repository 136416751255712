
import React from 'react';

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import LoadingSpinner from '../LoadingSpinner';
import fetchJson from '../../util/fetchJson';

export default function Scores() {
    const [loading, setLoading] = React.useState(true);
    const [users, setUsers] = React.useState({});
    const [scores, setScores] = React.useState([]);
    const [action, setAction] = React.useState('');

    React.useEffect(() => {
        loadData(setUsers, setScores, setLoading);
        const interval = setInterval(() => {
            fetchJson('/api/v1/metrics/user-time').then(setScores);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    // TODO: sort bys?

    return (<>
        { loading ? <LoadingSpinner /> : null}
        <Container fluid>
            <Row><Col><h4>User scores</h4></Col></Row>
            <Row><Col>
                <table className="table">
                    <TableHeader />
                    <tbody>
                        {scores.map(score =>
                            <UserRow key={score.userId} score={score} user={users[score.userId]} />)}
                    </tbody>
                </table>
            </Col></Row>
        </Container>
        <Modal animation={false} show={!!action} onHide={() => setAction('')}>
            <Modal.Header closeButton>
                <Modal.Title>{action}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            </Modal.Body>
        </Modal>
    </>);
}

function TableHeader() {
    return <thead><tr>
        <th scope="col">User</th>
        <th scope="col">Status</th>
        <th scope="col" title="Correct answers (not including bonuses)">Number Correct</th>
        <th scope="col">Latest Answer</th>
        <th scope="col">Computed Finish</th>
        <th scope="col">Actual Finish</th>
        <th scope="col">Hints (penalty)</th>
        <th scope="col">Bonus (bonus)</th>
        <th scope="col"></th>
    </tr></thead>;
}

function UserRow({ score, user }) {
    const status = score.finished ? "Finished" : `In Room ${score.currentCatId}`;
    return <tr>
        <td>{user?.id} - {user?.firstName} {user?.lastName}</td>
        <td>{status}</td>
        <td>{score.answerCount}</td>
        <td>
            {dateString(score.lastAnswerTime)}
        </td>
        {/* <td>
            {formatDuration(score.duration, { delimiter: '\n' })}
        </td> */}
        <td>
            {score.finished ? dateString(score.calculatedTime) : "Incomplete"}
        </td>
        <td>
            {score.finished ? dateString(score.lastAnswerTime) : "Incomplete"}
        </td>
        <td>{score.hintCount} (+{score.hintMinutes} min.)</td>
        <td>{score.bonusCount} (-{score.bonusMinutes} min.)</td>
        <td><Link to={`./users/${user?.id}`}>View answers</Link></td>
    </tr>;
}

function loadData(setUsers, setScores, setLoading) {
    setLoading(true);
    setUsers({});
    setScores([]);
    Promise.all([
        fetchJson('/api/v1/user'),
        fetchJson('/api/v1/metrics/user-time'),
    ]).then(([ users, scores ]) => {
        setScores(scores);
        const userMap = {};
        users.forEach(user => userMap[user.id] = user);
        setUsers(userMap);
    }).catch(console.log).finally(() => setLoading(false));
}

/**
 * convert millis to date string
 * @param {number} millis 
 * @returns string
 */
function dateString(millis) {
    return millis ? new Date(millis).toLocaleString().replace(', ', '\n') : "";
}
