
import React from 'react';

import Modal from "react-bootstrap/Modal";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import fetchJson from "../../util/fetchJson";
import { useAlert } from '../AlertContext';

export default function HintModal({question, close}) {
    // these are only hints the user has accessed
    const [hints, setHints] = React.useState({}); // id -> text
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setHints({});
        setLoading(true);
        // this is technically fetching more than we need...
        fetchJson('/api/v1/hint-used').then(hintsUsed => {
            const hints = {};
            hintsUsed.forEach(({ hint }) => hints[hint.id] = hint.hint);
            setHints(hints);
            setLoading(false);
        }).catch(console.log);
    }, [question]);

    const addHint = hint => setHints(oldHints => ({...oldHints, [hint.id]: hint.hint}));

    return <Modal size="lg" animation={false} show={!!question} onHide={close}>
        <Modal.Header closeButton>
            <Modal.Title>{question?.question} Hints</Modal.Title>
        </Modal.Header>
        <Modal.Body className={ loading ? "text-center" : "" }>
            {loading ? <Spinner animation="border" role="status" size="lg" className="m-4">
                <span className="sr-only">Loading...</span>
            </Spinner> : <>
                <p>Note: revealing a hint will result in a time penalty.</p>
                {question?.question_hints?.map(({ id }) => <HintRow key={id} hintId={id} hint={hints[id]} addHint={addHint} />)}
            </>}
        </Modal.Body>
    </Modal>;
}

function HintRow({ hintId, hint, addHint }) {
    const setAlert = useAlert();
    const onClick = () => fetchJson('/api/v1/hint/' + hintId).then(addHint).catch(error => {
        console.log(error);
        setAlert("Unable to load hint! Please reload and try again");
    });

    return <Row className="py-3 border-bottom"><Col>
        { hint ? <p style={{ whiteSpace: "pre-wrap"}}>{hint}</p> : <Button variant="dark" className="w-100" onClick={onClick}>Load hint</Button>}
    </Col></Row>
}
