
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import { Navigate, useParams } from 'react-router-dom';

import fetchJson from '../../util/fetchJson';

import { TableHeader, DataRow, AnswerDetails, markCorrect } from './Answers';
import Confirm from '../Confirm';
import { useAlert } from '../AlertContext';

const INTEGER_REGEX = /^\d+$/;

export default function UserAnswers() {
    const { userId } = useParams();
    const [user, setUser] = React.useState(null);
    const [answers, setAnswers] = React.useState({});
    const [questions, setQuestions] = React.useState([]);
    const [view, setView] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false);

    const [filter, setFilter] = React.useState("");
    const [questionFilter, setQuestionFilter] = React.useState("");
    const [answerFilter, setAnswerFilter] = React.useState("");
    const [toCorrect, setToCorrect] = React.useState(null);
    const setAlert = useAlert();

    React.useEffect(() => {
        loadData(userId, setUser, setAnswers, setQuestions)
    }, [userId, refresh]);

    React.useEffect(() => {
        setView(old => old ? { ...old, answers: answers[old.question.id]} : null);
    }, [answers]);

    if (!userId || !INTEGER_REGEX.test(userId)) {
        return <Navigate to="/" />;
    }

    const update = () => setRefresh(old => !old);

    // this is not the ideal way to filter but...
    let filtered = !filter ? questions : questions.filter(question => {
        const hasCorrect = !!(answers[question.id]?.find(a => a.correct));
        if (filter === "correct") {
            return hasCorrect;
        } else {
            return !hasCorrect;
        }
    });
    if (questionFilter) {
        filtered = filtered.filter(q => q.anomalyNumber.indexOf(questionFilter) >= 0 || q.question.indexOf(questionFilter) >= 0);
    }
    if (answerFilter) {
        filtered = filtered.filter(q => !!(answers[q.id]?.find(a => a.answer.indexOf(answerFilter) >= 0)));
    }
    const rowSetView = (question, answers) => setView({ title: question.question, question, answers });
    const onCorrect = () => markCorrect(toCorrect, setAlert).finally(() => {
        setToCorrect(null);
        update();
    });

    return (<>
        <Container>
            <Row><Col><h4>Scores for user {user?.id}: {user?.firstName} {user?.lastName}</h4></Col></Row>
            <Row className="mb-2">
                <Col xs="4"><Filter filter={filter} setFilter={setFilter} /></Col>
                <Col xs="4"><input type="text" className="form-control form-control-sm" placeholder="Question filter"
                    value={questionFilter} onChange={e => setQuestionFilter(e.target.value)} /></Col>
                <Col xs="4"><input type="text" className="form-control form-control-sm" placeholder="Answer filter"
                    value={answerFilter} onChange={e => setAnswerFilter(e.target.value)} /></Col>
            </Row>
            <Row><Col>
                <table className="table">
                    <TableHeader keyCol="Question" />
                    <tbody>
                        {filtered.map(question =>
                            <DataRow key={question.id} keyObj={question} setView={rowSetView}
                                keyText={`${question.id} - ${question.question}`}
                                answers={answers[question.id]} />)}
                    </tbody>
                </table>
            </Col></Row>
        </Container>
        <Modal size="lg" animation={false} show={!!view} onHide={() => setView(null)}>
            <Modal.Header closeButton>
                <Modal.Title>View</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AnswerDetails view={view} setToCorrect={setToCorrect}/>
            </Modal.Body>
        </Modal>
        <Confirm open={!!toCorrect} message="Are you sure you want to mark the answer as correct?"
            onConfirm={onCorrect} onCancel={() => setToCorrect(null)}/>
    </>);
}

function loadData(userId, setUser, setAnswers, setQuestions) {
    userId = parseInt(userId);
    setUser(null);
    setAnswers({});
    setQuestions([]);

    fetchJson('/api/v1/user').then(users => {
        setUser(users.find(user => user.id === userId));
    }).catch(console.log);

    fetchJson('/api/v1/answer?userId=' + userId).then(list => {
        const answers = {};
        list.forEach(answer => {
            if (!answers[answer.questionId]) {
                answers[answer.questionId] = [];
            }
            answers[answer.questionId].push(answer);
        });
        setAnswers(answers);
    }).catch(console.log);

    fetchJson('/api/v1/question').then(setQuestions).catch(console.log);
}

function Filter({ filter, setFilter }) {
    return <select className="form-control form-control-sm"
                value={filter} onChange={e => setFilter(e.target.value)}>
        <option value="">Show All</option>
        <option value="correct">Correct</option>
        <option value="incorrect">Incorrect</option>
    </select>;
}

